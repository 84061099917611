<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <div
      class="mt-10"
      style="background:white; min-height:700px"
    >
      <v-row>
        <v-col cols="6">
          <v-row class="d-flex justify-start align-center">
            <v-col
              cols="3"
              class="ml-7"
            >
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="selectedMonth"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="selectedMonth"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    @change="selectMonth"
                  />
                </template>
                <v-date-picker
                  v-model="selectedMonth"
                  type="month"
                  locale="ja"
                  no-title
                  scrollable
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                  >
                    {{ $t('messages.cancel') }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="selectMonth"
                  >
                    {{ $t('messages.ok') }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3">
              <v-btn
                color="primary"
                @click="dialogExport = true"
              >
                {{ $t('button.exportCsv') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="6"
          class="d-flex justify-end align-center"
        >
          <v-col cols="3">
            <span style="color:#5CA6D2">{{ $t('messages.factoryNameAndNumber') }}</span>
          </v-col>
          <v-col cols="6">
            <div class="align-self-center mr-9">
              <v-text-field
                v-model="search"
                name="pa-0 "
                outlined
              />
            </div>
          </v-col>
        </v-col>
      </v-row>
      <v-row
        v-if="unRegister === true"
        class="d-flex justify-center"
      >
        <v-col
          cols="8"
          style="border:1px solid red; border-radius: 20px"
        >
          <span class="red--text mt-5">{{ $t('view_titles.notice') }}</span>
          <v-data-table
            :headers="headersFactoriesNotRegister"
            :items="itemsFactoriesNotRegister"
            class="elevation-1 "
            :loading-text="$t('table.messages.loading')"
            :loading="loading"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 15, 30, 50],
              showFirstLastPage: false,
              'items-per-page-text': $t('table.messages.items_per_page'),
            }"
          >
            <template slot="no-data">
              {{ $t('table.messages.no_data') }}
            </template>
            <template
              v-slot:footer.page-text=""
              class="mr-0"
            >
              {{ $t('table.messages.page') }}
              {{ options.page }}
            </template>
            <template v-slot:footer.prepend />
          </v-data-table>
          <span class="red--text mt-5 d-flex justify-center">{{ $t('validator.checkRegisterRevenue') }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="items"
            class="elevation-1 "
            :loading-text="$t('table.messages.loading')"
            :loading="loading"
            :search="search"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 15, 30, 50],
              showFirstLastPage: false,
              'items-per-page-text': $t('table.messages.items_per_page'),
            }"
          >
            <template slot="no-data">
              {{ $t('table.messages.no_data') }}
            </template>
            <template
              v-slot:footer.page-text=""
              class="mr-0"
            >
              {{ $t('table.messages.page') }}
              {{ options.page }}
            </template>
            <template v-slot:footer.prepend />
          </v-data-table>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar">
        {{ snackbarMessage }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            {{ $t('messages.close') }}
          </v-btn>
        </template>
      </v-snackbar>
      <v-dialog
        v-model="dialogExport"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-text>
            <v-container>
              <v-row class="d-flex justify-center">
                <span class="mt-5">{{ $t('messages.titleRevenueExport') }} </span>
              </v-row>
              <v-row>
                <v-col
                  cols="6"
                  class="blue--text"
                >
                  {{ $t('messages.startAt') }}
                </v-col>
                <v-col
                  cols="6"
                  class="blue--text"
                >
                  {{ $t('messages.endAt') }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-menu
                    ref="menuStart"
                    v-model="menuStart"
                    :close-on-content-click="false"
                    :return-value.sync="startAt"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startAt"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="startAt"
                      :max="getMaxDate"
                      locale="ja"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="menuStart = false"
                      >
                        {{ $t('messages.cancel') }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menuStart.save(startAt)"
                      >
                        {{ $t('messages.ok') }}
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    ref="menuEnd"
                    v-model="menuEnd"
                    :close-on-content-click="false"
                    :return-value.sync="endAt"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="endAt"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="endAt"
                      locale="ja"
                      no-title
                      scrollable
                      :min="getMinDate"
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="menuEnd = false"
                      >
                        {{ $t('messages.cancel') }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menuEnd.save(endAt)"
                      >
                        {{ $t('messages.ok') }}
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-col cols="3">
              <json-excel
                :fetch="exportRevenue"
                :fields="headerExport"
                :name="exportHeadText + '-' + startAt + '-' + endAt"
              >
                <v-btn
                  color="blue darken-1"
                  block
                  type="submit"
                >
                  {{ $t("button.exportCsv") }}
                </v-btn>
              </json-excel>
            </v-col>
            <v-col cols="3">
              <v-btn
                color="dark darken-1"
                block
                @click="dialogExport = false"
              >
                {{ $t("button.cancel") }}
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  import moment from 'moment'
  import JsonExcel from 'vue-json-excel'
  import { api } from '@/config/index'
  import axios from 'axios'

  export default {
    name: 'RevenueManagementList',
    components: {
      JsonExcel,
    },
    data () {
      return {
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        selectedMonth: new Date().toISOString().substr(0, 7),
        search: '',
        options: {},
        headers: [
          {
            text: this.$t('table.headers.controlNumber'),
            align: 'center',
            value: 'code',
            class: 'font-weight-bold',
            width: '10em',
            sortable: false,
          },
          {
            text: this.$t('table.headers.factoryName'),
            align: 'center',
            value: 'name',
            class: 'font-weight-bold',
            width: '10em',
            sortable: false,
          },
        ],
        headersFactoriesNotRegister: [
          {
            text: this.$t('table.headers.controlNumber'),
            align: 'center',
            value: 'code',
            class: 'font-weight-bold',
            width: '10em',
            sortable: false,
          },
          {
            text: this.$t('table.headers.factoryName'),
            align: 'center',
            value: 'name',
            class: 'font-weight-bold',
            width: '10em',
            sortable: false,
          },
          {
            text: this.$t('table.headers.job'),
            align: 'center',
            value: 'job',
            class: 'font-weight-bold',
            width: '10em',
            sortable: false,
          },
          {
            text: this.$t('table.headers.shift'),
            align: 'center',
            value: 'shift',
            class: 'font-weight-bold',
            width: '10em',
            sortable: false,
          },
        ],
        items: [],
        itemsFactoriesNotRegister: [],
        loading: false,
        menu: false,
        unRegister: false,
        dialogExport: false,
        menuStart: false,
        menuEnd: false,
        startAt: '',
        endAt: '',
        headerExport: [],
        exportHeadText: '',
        exportData: [],
      }
    },
    computed: {
      ...get('customer', ['customerDetail']),
      ...get('revenue', ['listRevenueFactories', 'message', 'status', 'error']),
      getMinDate () {
        const startAt = this.startAt
        return startAt
      },
      getMaxDate () {
        const endAt = this.endAt
        return endAt
      },
    },
    watch: {
      status (value) {
        this.loading = value === 'loading'
      },
      error (value) {
        this.showSnackBar(value)
      },
      listRevenueFactories (value) {
        const tempData = value.listRevenue
        const timestamps = []
        const payload = []
        const divisionsHeaders = ['totalWorkHour', 'totalRevenue']
        tempData.forEach((el) => {
          el.values.forEach((division) => {
            if (!timestamps.find(o => o.timestamps === division.timestamps)) {
              timestamps.push(division)
            }
          })
        })
        tempData.map(el => {
          const data = { ...el }
          delete data.timestamps
          timestamps.forEach(division => {
            divisionsHeaders.forEach(divisionsHeader => {
              const dataByHeader = el.values.find(o =>
                o.timestamps === division.timestamps,
              )
              data[division.timestamps + divisionsHeader] = dataByHeader ? dataByHeader[divisionsHeader] || '0' : '0'
            })
          })
          payload.push(data)
          data.values.map(timestamps => {
            divisionsHeaders.map(divisionsHeader => {
              const tableHeaders = {
                text: timestamps.timestamps + ' ' + this.$t('messages.' + divisionsHeader),
                align: 'center',
                value: timestamps.timestamps + divisionsHeader,
                class: 'pr-0 pl-2 font-weight-bold',
                sortable: false,
                width: '8rem',
              }
              if (!this.headers.find((o) => o.value === tableHeaders.value)) {
                this.headers.push(tableHeaders)
              }
            })
          })
          divisionsHeaders.forEach((header) => {
            const tableHeader = {
              text: this.$t('table.headers.' + header),
              align: 'start',
              value: header,
              class: 'font-weight-bold',
              width: '8rem',
            }
            if (!this.headers.find((o) => o.value === tableHeader.value)) {
              this.headers.push(tableHeader)
            }
          })
          this.items = payload
        })
        if (value.listCustomerUnRegisterRevenue.length > 0 || value.listShiftUnRegisterRevenue.length > 0) {
          this.unRegister = true
          value.listCustomerUnRegisterRevenue.map(factory => {
            this.itemsFactoriesNotRegister.push({
              code: factory.code,
              name: factory.user.name,
            })
          })
          value.listShiftUnRegisterRevenue.map(jobs => {
            this.itemsFactoriesNotRegister.push({
              code: jobs.job.customer.code,
              name: jobs.job.customer.user.name,
              job: jobs.job.name,
              shift: jobs.shiftStartAt + '-' + jobs.shiftEndAt,
            })
          })
        } else {
          this.unRegister = false
        }
      },
    },
    created () {
      const payload = {
        month: moment(this.selectedMonth).format('MM/YYYY'),
      }
      this.$store.dispatch('revenue/getRevenueFactories', payload)
      this.exportHeadText = '売上管理'
      this.headerExport = {
        月: 'month',
        日付: 'workdate',
        工場: 'customer',
        ジョブ: 'job',
        シフト: 'shift',
        出勤時間: 'worktime',
        単価: 'revenueUnit',
        売上: 'revenue',
      }
    },
    methods: {
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      getIndex (index) {
        return (this.options.page - 1) * this.options.itemsPerPage + 1 + index
      },
      selectMonth () {
        this.itemsFactoriesNotRegister = []
        const payload = {
          month: moment(this.selectedMonth).format('MM/YYYY'),
        }
        this.$refs.menuStart.save(this.selectedMonth)
        this.$store.dispatch('revenue/getRevenueFactories', payload)
      },
      async exportRevenue () {
        this.dialogExport = false
        const payload = {
          startAt: this.startAt,
          endAt: this.endAt,
        }
        let fullUrl = api.baseUrl + '/revenue/render/'
        const query = new URLSearchParams(payload).toString()
        fullUrl += '?' + query
        const response = await axios.get(fullUrl)
        return response.data.data.result
      },
    },
  }
</script>
