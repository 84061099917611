var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"id":"dashboard-view","fluid":"","tag":"section"}},[_c('div',{staticClass:"mt-10",staticStyle:{"background":"white","min-height":"700px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',{staticClass:"d-flex justify-start align-center"},[_c('v-col',{staticClass:"ml-7",attrs:{"cols":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.selectedMonth,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.selectedMonth=$event},"update:return-value":function($event){_vm.selectedMonth=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar"},on:{"change":_vm.selectMonth},model:{value:(_vm.selectedMonth),callback:function ($$v) {_vm.selectedMonth=$$v},expression:"selectedMonth"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"type":"month","locale":"ja","no-title":"","scrollable":""},model:{value:(_vm.selectedMonth),callback:function ($$v) {_vm.selectedMonth=$$v},expression:"selectedMonth"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" "+_vm._s(_vm.$t('messages.cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.selectMonth}},[_vm._v(" "+_vm._s(_vm.$t('messages.ok'))+" ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialogExport = true}}},[_vm._v(" "+_vm._s(_vm.$t('button.exportCsv'))+" ")])],1)],1)],1),_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"cols":"6"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('span',{staticStyle:{"color":"#5CA6D2"}},[_vm._v(_vm._s(_vm.$t('messages.factoryNameAndNumber')))])]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"align-self-center mr-9"},[_c('v-text-field',{attrs:{"name":"pa-0 ","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])],1)],1),(_vm.unRegister === true)?_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticStyle:{"border":"1px solid red","border-radius":"20px"},attrs:{"cols":"8"}},[_c('span',{staticClass:"red--text mt-5"},[_vm._v(_vm._s(_vm.$t('view_titles.notice')))]),_c('v-data-table',{staticClass:"elevation-1 ",attrs:{"headers":_vm.headersFactoriesNotRegister,"items":_vm.itemsFactoriesNotRegister,"loading-text":_vm.$t('table.messages.loading'),"loading":_vm.loading,"footer-props":{
            itemsPerPageOptions: [5, 10, 15, 30, 50],
            showFirstLastPage: false,
            'items-per-page-text': _vm.$t('table.messages.items_per_page'),
          }},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('table.messages.page'))+" "+_vm._s(_vm.options.page)+" ")]},proxy:true},{key:"footer.prepend",fn:function(){return undefined},proxy:true}],null,false,2191294191)},[_c('template',{slot:"no-data"},[_vm._v(" "+_vm._s(_vm.$t('table.messages.no_data'))+" ")])],2),_c('span',{staticClass:"red--text mt-5 d-flex justify-center"},[_vm._v(_vm._s(_vm.$t('validator.checkRegisterRevenue')))])],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1 ",attrs:{"headers":_vm.headers,"items":_vm.items,"loading-text":_vm.$t('table.messages.loading'),"loading":_vm.loading,"search":_vm.search,"footer-props":{
            itemsPerPageOptions: [5, 10, 15, 30, 50],
            showFirstLastPage: false,
            'items-per-page-text': _vm.$t('table.messages.items_per_page'),
          }},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('table.messages.page'))+" "+_vm._s(_vm.options.page)+" ")]},proxy:true},{key:"footer.prepend",fn:function(){return undefined},proxy:true}])},[_c('template',{slot:"no-data"},[_vm._v(" "+_vm._s(_vm.$t('table.messages.no_data'))+" ")])],2)],1)],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
          var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('messages.close'))+" ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")]),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialogExport),callback:function ($$v) {_vm.dialogExport=$$v},expression:"dialogExport"}},[_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',{staticClass:"d-flex justify-center"},[_c('span',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.$t('messages.titleRevenueExport'))+" ")])]),_c('v-row',[_c('v-col',{staticClass:"blue--text",attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.$t('messages.startAt'))+" ")]),_c('v-col',{staticClass:"blue--text",attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.$t('messages.endAt'))+" ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menuStart",attrs:{"close-on-content-click":false,"return-value":_vm.startAt,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.startAt=$event},"update:return-value":function($event){_vm.startAt=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.startAt),callback:function ($$v) {_vm.startAt=$$v},expression:"startAt"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuStart),callback:function ($$v) {_vm.menuStart=$$v},expression:"menuStart"}},[_c('v-date-picker',{attrs:{"max":_vm.getMaxDate,"locale":"ja","no-title":"","scrollable":""},model:{value:(_vm.startAt),callback:function ($$v) {_vm.startAt=$$v},expression:"startAt"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menuStart = false}}},[_vm._v(" "+_vm._s(_vm.$t('messages.cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menuStart.save(_vm.startAt)}}},[_vm._v(" "+_vm._s(_vm.$t('messages.ok'))+" ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menuEnd",attrs:{"close-on-content-click":false,"return-value":_vm.endAt,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.endAt=$event},"update:return-value":function($event){_vm.endAt=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.endAt),callback:function ($$v) {_vm.endAt=$$v},expression:"endAt"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuEnd),callback:function ($$v) {_vm.menuEnd=$$v},expression:"menuEnd"}},[_c('v-date-picker',{attrs:{"locale":"ja","no-title":"","scrollable":"","min":_vm.getMinDate},model:{value:(_vm.endAt),callback:function ($$v) {_vm.endAt=$$v},expression:"endAt"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menuEnd = false}}},[_vm._v(" "+_vm._s(_vm.$t('messages.cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menuEnd.save(_vm.endAt)}}},[_vm._v(" "+_vm._s(_vm.$t('messages.ok'))+" ")])],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"3"}},[_c('json-excel',{attrs:{"fetch":_vm.exportRevenue,"fields":_vm.headerExport,"name":_vm.exportHeadText + '-' + _vm.startAt + '-' + _vm.endAt}},[_c('v-btn',{attrs:{"color":"blue darken-1","block":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("button.exportCsv"))+" ")])],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"color":"dark darken-1","block":""},on:{"click":function($event){_vm.dialogExport = false}}},[_vm._v(" "+_vm._s(_vm.$t("button.cancel"))+" ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }